/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pixi.js@5.3.6/dist/pixi.min.js
 * - /npm/gsap@3.8.0/dist/gsap.min.js
 * - /npm/gsap@3.8.0/dist/PixiPlugin.min.js
 * - /npm/@pixi/graphics-extras@5.3.6/dist/graphics-extras.min.js
 * - /npm/gsap@3.8.0/dist/ScrollTrigger.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
